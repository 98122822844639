const Contact = () => {
	return (
		<article>
			<h2>Contact</h2>
			<ul id="contact-list">
				<li>
					<address>
						<a
							className="li-with-icon"
							href="mailto:zandermaxwell@hey.com"
							rel="noreferrer"
							target="_blank"
						>
							<p>zandermaxwell@hey.com</p>
							<img
								alt="mail"
								className="contact-icon"
								src="https://raw.githubusercontent.com/FortAwesome/Font-Awesome/6.x/svgs/regular/envelope.svg"
							/>
						</a>
					</address>
				</li>
				<li>
					<span>
						<a
							className="li-with-icon"
							href="tel:+14402539629"
							rel="noreferrer"
							target="_blank"
						>
							<p>+1-440-253-9629</p>
							<img
								alt="phone"
								className="contact-icon"
								src="https://raw.githubusercontent.com/FortAwesome/Font-Awesome/6.x/svgs/solid/phone.svg"
							/>
						</a>
					</span>
				</li>
				<li>
					<span>
						<a
							className="li-with-icon"
							href="https://www.linkedin.com/in/zandermax"
							rel="noreferrer"
							target="_blank"
						>
							<p>linkedin.com/in/zandermax</p>
							<img
								alt="LinkedIn logo"
								className="contact-icon"
								id="logo-linkedin"
								src="https://raw.githubusercontent.com/Automattic/social-logos/trunk/svg-min/linkedin.svg"
							/>
						</a>
					</span>
				</li>
				<li>
					<span>
						<a
							className="li-with-icon"
							href="https://www.github.com/zandermax"
							rel="noreferrer"
							target="_blank"
						>
							<p>github.com/zandermax</p>
							<img
								alt="GitHub logo"
								className="contact-icon"
								src="https://raw.githubusercontent.com/primer/octicons/main/icons/mark-github-16.svg"
							/>
						</a>
					</span>
				</li>
			</ul>
		</article>
	);
};

export default Contact;
